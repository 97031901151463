<template>
  <section class="invoice-preview-wrapper">
    <b-row
      v-if="item"
      class="invoice-preview"
    >
      <b-col cols="12">
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <b-card-body>
            <div class="d-flex justify-content-between flex-md-row flex-column">
              <div>
                <p class="invoice-number">
                  <b>{{ $t(`reviews.types.${item.type}`) }} #{{ item.id }}</b>
                </p>
                <p class="invoice-date-title">
                  {{ item.reviewable.title }}
                </p>
              </div>

              <div class="mt-md-0 mt-2">
                <div
                  v-if="$ability.can('update', 'reviews')"
                  class="d-flex flex-column align-items-end"
                >
                  <span
                    :class="`btn btn-sm btn-${item.status === 'rejected' ? 'danger' : (item.status === 'pending' ? 'warning' : 'success')}`"
                    @click="changeStatus(item.id)"
                  >
                    {{ $t(`general.statuses.${item.status}`) }}

                    <feather-icon
                      icon="EditIcon"
                      size="16"
                      class="align-middle ml-2"
                    />
                  </span>
                  <div class="mt-2">
                    <b-button
                      v-if="!editable"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      block
                      @click="makeEditable"
                    >
                      {{ $t('orders.make_editable') }}
                    </b-button>
                    <div
                      v-else
                      class="d-flex"
                    >
                      <b-button
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="primary"
                        class="mr-2"
                        @click="saveReview"
                      >
                        {{ $t('general.save') }}
                      </b-button>
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="outline-secondary"
                        @click="makeNotEditable"
                      >
                        {{ $t('general.cancel') }}
                      </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        xl="6"
      >
        <b-card
          no-body
          class="invoice-preview-card"
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="12"
              >
                <label for="name">{{ $t('admin.table.fields.name') }}</label>
                <b-form-input
                  :id="`name`"
                  v-model="item.name"
                  class="d-inline-block"
                  :readonly="!editable"
                />
              </b-col>
              <b-col
                cols="12"
              >
                <label for="email">{{ $t('admin.table.fields.email') }}</label>
                <b-form-input
                  :id="`email`"
                  v-model="item.email"
                  class="d-inline-block"
                  :readonly="!editable"
                />
              </b-col>
            </b-row>
          </b-card-body>
        </b-card>

        <b-card
          no-body
          class="invoice-preview-card"
        >
          <b-card-body>
            <b-row>
              <b-col
                cols="6"
              >
                <label for="rating">{{ $t('admin.table.fields.rating') }}</label>
                <b-form-select
                  :id="`rating`"
                  v-model="item.rating"
                  class="d-inline-block"
                  :readonly="!editable"
                  :options="[1,2,3,4,5]"
                />
              </b-col>
              <b-col
                cols="6"
              >
                <label for="created_at">{{ $t('admin.table.fields.created_at') }}</label>
                <b-form-datepicker
                  :id="`created_at`"
                  v-model="item.created_at"
                  class="d-inline-block"
                  :readonly="!editable"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                />
              </b-col>
              <b-col
                cols="12"
              >
                <label for="description">{{ $t('admin.table.fields.description') }}</label>
                <b-form-textarea
                  :id="`description`"
                  v-model="item.description"
                  class="d-inline-block"
                  :readonly="!editable"
                  rows="8"
                />
              </b-col>
              <b-col
                cols="12"
              >
                <label for="pluses">{{ $t('admin.table.fields.pluses') }}</label>
                <b-form-textarea
                  :id="`pluses`"
                  v-model="item.plus"
                  class="d-inline-block"
                  :readonly="!editable"
                  rows="2"
                />
              </b-col>
              <b-col
                cols="12"
              >
                <label for="minuses">{{ $t('admin.table.fields.minuses') }}</label>
                <b-form-textarea
                  :id="`minuses`"
                  v-model="item.minus"
                  class="d-inline-block"
                  :readonly="!editable"
                  rows="2"
                />
              </b-col>
              <b-col
                cols="12"
              >
                <label for="video">{{ $t('admin.table.fields.video') }}</label>
                <b-form-input
                  :id="`video`"
                  v-model="item.video"
                  class="d-inline-block"
                  :readonly="!editable"
                />
              </b-col>
            </b-row>
            <b-card-body
              v-if="item.gallery"
              class="invoice-padding pt-0"
            >
              <span class="font-weight-bold">{{ $t('admin.table.fields.gallery') }}: </span>
              <div>
                <b-img
                  v-for="(file, index) in item.gallery"
                  :key="index"
                  rounded
                  :src="file.media ? file.media.url : ''"
                  height="80"
                  class="mr-1 mb-1"
                />
              </div>
            </b-card-body>
          </b-card-body>
        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="invoice-actions"
      >
        <b-card v-if="$ability.can('update', 'reviews')">
          <b-form-group
            :label="$t('reviews.text.reply')"
            :label-for="'reply.review'"
          >
            <b-form-textarea
              :id="'reply.review'"
              v-model="reply"
            />
          </b-form-group>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-75"
            block
            @click="sendReply"
          >
            {{ $t('reviews.text.send_reply') }}
          </b-button>
        </b-card>

        <b-card
          v-for="(child, index) in item.children"
          :key="index"
          no-body
          class="invoice-preview-card"
        >
          <b-card-body class="invoice-padding pb-0">
            <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
              <div>
                <span class="invoice-number">#{{ child.id }} / {{ child.created_at }}</span>
              </div>
              <div class="mt-md-0 mt-2">
                <span
                  class="btn btn-danger btn-sm float-right"
                  @click="destroy(child.id)"
                >
                  <feather-icon
                    icon="TrashIcon"
                    size="16"
                    class="align-middle"
                  />
                </span>
              </div>
            </div>
          </b-card-body>
          <hr class="invoice-spacing">
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('admin.table.fields.status') }}: </span>
            <span>
              {{ $t(`general.statuses.${child.status}`) }}
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1"
                block
                :style="`width: 200px;`"
                @click="changeStatus(child.id)"
              >
                {{ $t('orders.change_status') }}
              </b-button>
            </span>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('admin.table.fields.name') }}: </span>
            <span>{{ child.name ? child.name : child.user.name }}</span>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('admin.table.fields.email') }}: </span>
            <span>{{ child.email ? child.email : child.user.email }}</span>
          </b-card-body>
          <b-card-body class="invoice-padding pt-0">
            <span class="font-weight-bold">{{ $t('admin.table.fields.description') }}: </span>
            <span>{{ child.description }}</span>
          </b-card-body>
          <hr class="invoice-spacing">
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import GlobalMixin from '@/mixins/GlobalMixin'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import SweetAlertMixin from '@/mixins/SweetAlertMixin'

export default {
  mixins: [GlobalMixin, SweetAlertMixin],
  data() {
    return {
      item: null,
      reply: null,
      editable: false,
    }
  },
  beforeCreate() {
    this.$http.get(`/api/admin/reviews/${router.currentRoute.params.id}`)
      .then(response => {
        const { data } = response.data

        this.item = this.transformData(data)
      })
      .catch(error => {
        if (error.response.status === 404) {
          this.item = null
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.not_found'),
              icon: 'AlertCircleIcon',
              variant: 'warning',
            },
          })
          router.replace({ name: 'reviews-index' })
        }
      })
  },
  methods: {
    transformData(item) {
      return {
        id: item.id,
        status: item.status,
        description: item.description,
        plus: item.plus,
        minus: item.minus,
        video: item.video,
        rating: item.rating,
        type: item.type,
        reviewable: item.reviewable,
        created_at: item.created_at,
        children: item.children,
        email: item.email || item.user.email,
        name: item.name || item.user.name,
        gallery: null,
      }
    },
    makeEditable() {
      this.editable = !this.editable
    },
    makeNotEditable() {
      this.editable = false
    },
    saveReview() {
      const data = {
        name: this.item.name,
        email: this.item.email,
        description: this.item.description,
        plus: this.item.plus,
        minus: this.item.minus,
        video: this.item.video,
        rating: this.item.rating,
        created_at: this.item.created_at,
      }
      this.$http.put(`/api/admin/reviews/${this.item.id}`, data)
        .then(() => {
          this.editable = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('notifications.successfully'),
              text: this.$t('notifications.success.updated'),
              icon: 'CoffeeIcon',
              variant: 'success',
            },
          })
        })
        .catch(() => {
          this.editable = true
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'XIcon',
              variant: 'danger',
            },
          })
        })
    },
    changeStatus(id) {
      this.confirm(result => {
        this.$http.put(`/api/admin/reviews/${id}/${result.value}`)
          .then(response => {
            const { data } = response.data
            this.item.status = data.status
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.successfully'),
                text: this.$t('notifications.success.updated'),
                icon: 'CoffeeIcon',
                variant: 'success',
              },
            })
          })
      }, {
        input: 'select',
        inputCustomClass: 'form-control',
        inputOptions: {
          approved: this.$t('general.statuses.approved'),
          pending: this.$t('general.statuses.pending'),
        },
        textarea: 'select',
        text: this.$t('notifications.confirm.update.text'),
      })
    },
    sendReply() {
      this.$http.post(`/api/admin/reviews/${router.currentRoute.params.id}/reply`, {
        description: this.reply,
      })
        .then(() => this.refetchData())
    },
    destroy(id) {
      this.confirm(() => {
        this.$http.delete(`/api/admin/reviews/${id}`)
          .then(() => this.refetchData())
          .catch(error => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: error.response.data.message,
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
          })
      })
    },
    refetchData() {
      this.$http.get(`/api/admin/reviews/${router.currentRoute.params.id}`)
        .then(response => {
          const { data } = response.data
          this.item = this.transformData(data)
          this.reply = null
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.item = null
            this.reply = null
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: this.$t('notifications.not_found'),
                icon: 'AlertCircleIcon',
                variant: 'warning',
              },
            })
            router.replace({ name: 'reviews-index' })
          }
        })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>

<style lang="scss">
@media print {

  // Global Styles
  body {
    background-color: transparent !important;
  }
  nav.header-navbar {
    display: none;
  }
  .main-menu {
    display: none;
  }
  .header-navbar-shadow {
    display: none !important;
  }
  .content.app-content {
    margin-left: 0;
    padding-top: 2rem !important;
  }
  footer.footer {
    display: none;
  }
  .card {
    background-color: transparent;
    box-shadow: none;
  }
  .customizer-toggle {
    display: none !important;
  }

  // Invoice Specific Styles
  .invoice-preview-wrapper {
    .row.invoice-preview {
      .col-md-8 {
        max-width: 100%;
        flex-grow: 1;
      }

      .invoice-preview-card {
        .card-body:nth-of-type(2) {
          .row {
            > .col-12 {
              max-width: 50% !important;
            }

            .col-12:nth-child(2) {
              display: flex;
              align-items: flex-start;
              justify-content: flex-end;
              margin-top: 0 !important;
            }
          }
        }
      }
    }

    // Action Right Col
    .invoice-actions {
      display: none;
    }
  }
}
</style>
